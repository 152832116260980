/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import Link from 'components/Link';
import { segmentTrack } from 'utils/segment';

function Footer({ rawdata, locale, className }) {
  return (
    <footer>
      <Container fluid className="bg-black py-6">
        <Row>
          <Col
            sm={12}
            md={12}
            className=" text-center text-sm-start text-md-start text-xl-start"
          >
            <Row className="justify-content-center">
              <Col className="mt-4 " md={3} sm={3} lg={3} xl={2}>
                <Row>
                  <Col>
                    <p className="mb-4 h5 text-xlight p-0 fw-bold">
                      Midasboard
                    </p>
                    <p
                      className="text-greys-medium mt-1 ms-0 ps-0 mb-2"
                      dangerouslySetInnerHTML={{ __html: rawdata.address }}
                    />

                    {rawdata &&
                      rawdata.social_sites.map((item, index) => (
                        <Link
                          target="_blank"
                          rel="noopener"
                          data-ana-apply-intent="false"
                          data-meta={`footer-primary-follow-${index}--link`}
                          id={`footer-primary-follow-${index}--link`}
                          key={`footer-primary-follow-${index}--link`}
                          data-ana={item.social_site_name}
                          aria-label={`Visit our Midasboard ${item.social_site_name[0].toUpperCase()}${item.social_site_name.slice(
                            1,
                          )} page, opens in a new tab`}
                          variant="basic"
                          disabled={false}
                          type="external"
                          to={item.link}
                          nodecoration="true"
                          className="text-white ps-0 pe-2 py-2 d-inline"
                          onClick={() => {
                            // segmentTrack(item.events && item.events);
                          }}
                        >
                          <Icon
                            variant={item.social_site_name}
                            className="text-greys-medium"
                          />
                        </Link>
                      ))}
                  </Col>
                </Row>
              </Col>
              {rawdata &&
                rawdata.footer_links.map((footerlink, index) => (
                  <Col md={2} sm={3} lg={2} xl={2} className="mt-4" key={index}>
                    <p className="text-xlight mt-0 me-0 mb-4 ms-0 fw-bold h5">
                      {' '}
                      {footerlink.header}{' '}
                    </p>

                    {footerlink.list_of_links.map(list => (
                      <Col
                        key={list.name}
                        md={12}
                        className="text-greys-medium"
                      >
                        <Link
                          variant="basic"
                          disabled={false}
                          type={
                            list.mailto_link
                              ? 'mail'
                              : list.relative_url
                                ? ''
                                : ''
                          }
                          to={
                            list.mailto_link
                              ? `mailto:${list.mailto_link}`
                              : list.relative_url
                                ? list.relative_url
                                : ''
                          }
                          nodecoration="true"
                          className="text-greys-medium border-0 p-0 mb-2"
                          onClick={() => {
                            // list.events && segmentTrack(list.events);
                          }}
                        >
                          {list.name}
                        </Link>
                      </Col>
                    ))}
                  </Col>
                ))}

              <Col md={3} sm={3} lg={3} xl={2} className="mt-4">
                <p className="mb-4 h5 text-xlight p-0 fw-bold">Contact</p>

                <p
                  className="text-greys-medium mt-1 ms-0 ps-0 mb-2"
                  dangerouslySetInnerHTML={{ __html: rawdata && rawdata.blurb }}
                />
                <p className="text-greys-medium m-0 p-0">Email Us:</p>
                <Link
                  variant="basic"
                  disabled={false}
                  type="mail"
                  to={`mailto:${rawdata.email_address}`}
                  className="text-xlight border-0 mt-0 mx-0 mb-1 p-0"
                  nodecoration="true"
                >
                  {rawdata.email_address}
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <Row className="p-0 justify-content-center">
              <div className="m-4">
                <Logo />
              </div>
            </Row>
            <Row>
              <p className="text-greys-medium m-0">{rawdata.copyright}</p>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
