/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Panel from 'components/Panel';
import Input from 'components/Form/Input';
import { Row, Col } from 'react-bootstrap';
import { PanelContent, ScrollableContent } from 'components/Panel/tradeview';
import DropDown from 'components/DropDown';
import Card from 'components/Card';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { makeSelectLocale } from 'containers/App/selectors';
import { formatters } from '../../../utils/formatters';
import { loadReviewData } from '../../../containers/TradeView/actions';

let timeout;
const LeftPanel = ({ property, toggleLeft, isLeftOpen, api, active }) => {
  const [selectedKey, setSelectedKey] = useState('All Issues');
  const [filteredResults, setfilteredResults] = useState(property);
  const [searchText, setSearchText] = useState('');
  const stateSelector = createStructuredSelector({
    locale: makeSelectLocale(),
  });

  const { locale } = useSelector(stateSelector);
  const dispatch = useDispatch();

  //based on state
  const ddopts = property
    .filter(j => j.state === 'IPO_ACQUIRED' || j.state === 'COMING_SOON')
    .sort((a, b) =>
      a.name.localeCompare(b.name, 'en', {
        numeric: true,
        sensitivity: 'base',
      }),
    )
    .map(e => e.location);

  const additionalProp = new Set();
  additionalProp.add('All Issues');
  const optlabels = new Set([...additionalProp, ...ddopts]);

  // console.log(optlabels, 'optlabels');

  const history = useHistory();
  const ddOptions = property
    ? {
        label: 'All Issues',
        options: [...new Set(optlabels)].map(e => ({
          key: e,
          label: e,
        })),
      }
    : {
        label: '',
        options: [],
      };

  // console.log(ddOptions, 'ddOptions');

  // search dropdown
  const handleSelect = e => {
    setSelectedKey(e.target.text);
  };

  // Search Input
  const handleChange = e => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handlePropertySelected = value => {
    dispatch(loadReviewData());
    history.push(value);
  };

  const getSearchQuery = term => {
    if (!term) {
      return null;
    }
    const parts = term.split(' ').filter(e => e);
    if (parts.length === 0) {
      return null;
    }
    let content = '(';
    for (let i = 0; i < parts.length; i++) {
      if (i !== 0) {
        content += ' AND ';
      }
      content += `(name:${parts[i]}* OR name:${parts[i]}~)`;
    }
    content += ')';
    return content;
  };

  // const submitSearch = debounce => {
  //   clearTimeout(timeout);
  //   timeout = setTimeout(async () => {
  //     if (!searchText && (!selectedKey || selectedKey === 'All Issues')) {
  //       setfilteredResults(property);
  //     }
  //     try {
  //       // let search = encodeURIComponent(`language:${locale}`);
  //       let search = "";
  //       if (searchText) {
  //         const query = getSearchQuery(searchText);
  //         search += ` AND ${query}`;
  //       }
  //       // if (selectedKey && selectedKey != 'All Locations') {
  //       //   search += ` AND location:'${selectedKey}'`;
  //       // }
  //       // const include = encodeURIComponent(`['id']`);
  //       // const url = `api/issues?text=${search}&include=${include}`;
  //       // const result = await api.get(url);
  //       // const tempResults = property.filter(
  //       //   prop => result.indexOf(prop.id) > -1,
  //       // );
  //       setfilteredResults(property);
  //     } catch (error) {
  //       // silently fail
  //       console.error(error);
  //     }
  //   }, debounce);
  // };

  // useEffect(() => {
  //   submitSearch(500);
  // }, [searchText]);

  // useEffect(() => {
  //   submitSearch(0);
  // }, [selectedKey]);

  useEffect(() => {
    setfilteredResults(property);
  }, [property]);

  return (
    <>
      <Panel variant="left" isOpen={isLeftOpen} onToggle={toggleLeft}>
        <PanelContent className="p-0 pt-4">
          <div className="px-4 px-md-6 pb-2 bg-white">
            <Row>
              <Col>
                <Input
                  type="text"
                  fieldid="search"
                  value={searchText}
                  label={
                    locale === 'en'
                      ? 'Search All Issues'
                      : 'Rechercher toutes les propriétés'
                  }
                  onChange={e => handleChange(e)}
                />
                <DropDown className="d-block mt-4" width="100%">
                  <DropDown.Toggle
                    label={ddOptions.label}
                    value={selectedKey}
                    variant="none"
                    disabled={false}
                  />
                  <DropDown.Menu className="w-100">
                    {ddOptions.options.map(option => (
                      <DropDown.Item
                        {...option}
                        key={option.key}
                        eventKey={option.key}
                        onClick={e => {
                          handleSelect(e);
                        }}
                      >
                        {option.label}
                      </DropDown.Item>
                    ))}
                  </DropDown.Menu>
                </DropDown>
              </Col>
            </Row>
          </div>
          {filteredResults && (
            <ScrollableContent className="px-4 px-md-6">
              {!filteredResults.length && (
                <p className="my-3">
                  {locale === 'en'
                    ? 'No data found'
                    : 'Aucune donnée disponible'}
                </p>
              )}
              {filteredResults.length > 0 && filteredResults
                .map(value => (
                  <Card
                    size="sm"
                    key={`property-${value && value.id}`}
                    tabIndex="0"
                    className={
                      value.state === 'IPO_ACQUIRED'
                        ? 'my-1'
                        : 'my-1 shadow-none'
                    }
                    style={{
                      cursor: value.state === 'IPO_ACQUIRED' && 'pointer',
                      border:
                        value.symbol === active ? '3px solid #4cd683' : null,
                      borderColor: value.state !== 'IPO_ACQUIRED' && '#fff',
                    }}
                    onClick={() =>
                      handlePropertySelected(value.symbol)
                    }
                  >
                    <Card.Content>
                      {/* <Card.Img
                        src={
                          value &&
                          `${
                            (
                              value.images.filter(
                                e => e.category == 'main',
                              )[0] || value.images[0]
                            ).url
                          }?w=200&ar=9:9&fit=crop`
                        }
                        size="sm"
                        alt={value && value.name}
                      /> */}
                      <Card.Body>
                        <Card.Body.Left>
                          <p className="w-100 mb-0 text-greys-dark">
                            {value && value.location}
                            {value.symbol === selectedKey}
                          </p>
                          <p className="w-100 mb-0 text-black">
                            {value && value.name}
                          </p>
                          {/* <p className="w-100 mb-0 text-greys-dark">
                            {value &&
                              value.ipoDetails &&
                              formatters.currency(
                                value.ipoDetails.pricePerShare,
                              )}
                          </p> */}
                        </Card.Body.Left>
                      </Card.Body>
                    </Card.Content>
                  </Card>
                ))}
            </ScrollableContent>
          )}
        </PanelContent>
      </Panel>
    </>
  );
};

export default LeftPanel;
